<template>
  <section :class="$style.promoArticles">
    <div :class="$style.wrapper">
      <el-button
        type="primary"
        :class="$style.button"
        @click="$router.push('promo-articles/create')"
      >
        Создать
      </el-button>
    </div>
    <el-table :data="promo" stripe>
      <el-table-column prop="name" label="Название акции"> </el-table-column>
      <el-table-column prop="startDate" label="Дата начала" width="200">
        <template slot-scope="scope">
          {{ formatDate(scope.row.startDate) }}
        </template>
      </el-table-column>
      <el-table-column prop="expireDate" label="Дата окончания" width="200">
        <template slot-scope="scope">
          {{ formatDate(scope.row.expireDate) }}
        </template>
      </el-table-column>
      <el-table-column prop="isActive" label="Активный" width="200">
        <template slot-scope="scope">
          <input
            type="checkbox"
            onclick="return false"
            :checked="scope.row.isActive"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <ActionButtons
            :class="$style.buttons"
            :edit-link="'promo-articles/' + String(scope.row.id)"
            @delete="remove(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import { formatDate } from '@/helpers/index.js'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  components: { ActionButtons },
  data() {
    return {
      promo: [],
      total: 0,
      page: 1,
      limit: 20,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    formatDate(date) {
      return formatDate(date)
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.ContentServiceCore.PromoArticlesActions.getList({
          limit: this.limit,
          page: this.page,
        })
      if (error) return
      this.promo = value.data
      this.total = value.meta.count
      loading.close()
    },

    async remove(id) {
      const isConfirm = confirm('Вы точно хотите удалить акцию?')
      if (!isConfirm) {
        return
      }
      const res = await delivery.ContentServiceCore.PromoArticlesActions.delete(
        id,
      )
      if (!res.error) {
        this.getList()
      }
    },
  },
}
</script>

<style lang="scss" module>
.promoArticles {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin: 1rem 0 1.5rem;
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
